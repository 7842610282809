<template>
  <base-btn
    color="primary"
    outlined
    @click="handleDeleteAccount"
    v-bind="$attrs"
    v-on="$listeners"
  >
    {{ $t("deleteAccount") }}
  </base-btn>
</template>
<script>
export default {
  name: "DeleteAccountBtn",
  methods: {
    handleDeleteAccount() {
      window.open(
        "https://foodeo.es/blog/cancelacion-de-cuenta/",
        "_system",
        "location=yes"
      );
    },
  },
};
</script>
